<template>
  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
          <ValidationProvider name="Accommodation Type" vid="accommodation_type" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="accommodation_type"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('elearning_iabm.accommodation') }} <span class="text-danger">*</span>
              </template>
              <b-form-radio-group
                  id="accommodation_type"
                  v-model="formData.accommodation_type"
                  :options="options"
                  name="accommodation_type"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-radio-group>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Mobile" vid='mobile' rules="required">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="mobile"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('globalTrans.mobile')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                  type="number"
                  id="mobile"
                  v-model="formData.mobile"
                  :state="errors[0] ? false : (valid ? true : null)"
                  @change="getPersonalInfo"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Name" vid='name' rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="name"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('user.namel')}}
              </template>
              <b-form-input
                  id="name"
                  readonly
                  v-model="formData.name"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="NID" vid='nid' rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="nid"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('externalUserIrrigation.nid')}}
              </template>
              <b-form-input
                  readonly
                  type="number"
                  id="nid"
                  v-model="formData.nid"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Date Of Birth" vid="date_of_birth" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="date_of_birth"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('externalUserIrrigation.date_of_birth')}}
              </template>
              <b-form-input
                  readonly
                  class="fromDate"
                  v-model="formData.date_of_birth"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Email" vid='email' rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="email"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('externalTraining.email')}}
              </template>
              <b-form-input
                  readonly
                  id="email"
                  v-model="formData.email"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="circular_memo_no"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                  id="circular_memo_no"
                  v-model="formData.circular_memo_no"
                  :state="errors[0] ? false : (valid ? true : null)"
                  @change="getCircularPublicationInfo"
              ></b-form-input>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Training Type"  vid="training_type_id" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_type_id"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('elearning_config.training_type')}}
              </template>
              <b-form-select
              plain
              v-model="formData.training_type_id"
              :options="trainingTypeList"
              id="training_type_id"
              :state="errors[0] ? false : (valid ? true : null)"
              readonly
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Training Category" vid="training_category_id" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_category_id"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('elearning_config.training_category')}}
              </template>
              <b-form-select
              plain
              v-model="formData.training_category_id"
              :options="trainingCategoryList"
              id="training_category_id"
              :state="errors[0] ? false : (valid ? true : null)"
              readonly
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Training Title" vid="training_title_id" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_title_id"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('elearning_config.training_title')}}
              </template>
              <b-form-select
              plain
              v-model="formData.training_title_id"
              :options="trainingTitleList"
              id="training_title_id"
              :state="errors[0] ? false : (valid ? true : null)"
              readonly
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Organization"  vid="org_id" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="org_id"
              slot-scope="{ valid, errors }"
              v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1"
          >
              <template v-slot:label>
              {{$t('elearning_config.organization')}}
              </template>
              <b-form-select
                  plain
                  v-model="formData.org_id"
                  :options="orgList"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  readonly
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Office Type" vid="office_type_id" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="office_type_id"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('elearning_config.office_type')}}
              </template>
              <b-form-select
                  plain
                  v-model="formData.office_type_id"
                  :options="officeTypeList"
                  id="office_type_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  readonly
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Office" vid="office_id" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="office_id"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{$t('globalTrans.office')}}
              </template>
              <b-form-select
                  plain
                  v-model="formData.office_id"
                  :options="officeList"
                  id="office_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  readonly
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="remarks"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('elearning_config.remarks_en') }}
              </template>
              <b-form-textarea
                  rows="2"
                  id="remarks"
                  v-model="formData.remarks"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-textarea>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
          <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="remarks_bn"
              slot-scope="{ valid, errors }"
          >
              <template v-slot:label>
              {{ $t('elearning_config.remarks_bn') }}
              </template>
              <b-form-textarea
                  rows="2"
                  id="remarks_bn"
                  v-model="formData.remarks_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
              ></b-form-textarea>
              <div class="invalid-feedback">
              {{ errors[0] }}
              </div>
          </b-form-group>
          </ValidationProvider>
          <div class="row">
          <div class="col-sm-3"></div>
          <div class="col text-right">
              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
              &nbsp;
              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
          </div>
          </div>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingApplicationStore, trainingApplicationUpdate, trainingApplicationPersonalInfo, trainingApplicationCircularPublicationInfo } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        accommodation_type: 2,
        mobile: '',
        name: '',
        date_of_birth: '',
        nid: '',
        email: '',
        circular_memo_no: '',
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      officeTypeList: [],
      officeList: [],
      trainingCategoryList: [],
      trainingTitleList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    options: function () {
      return [
        { text: this.currentLocale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', value: '1' },
        { text: this.currentLocale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', value: '2' }
      ]
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    async getPersonalInfo () {
      const result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationPersonalInfo, { mobile: this.formData.mobile })
      if (result.success) {
        this.formData.name = result.data.name
        this.formData.email = result.data.email
        this.formData.date_of_birth = result.data.date_of_birth
        this.formData.nid = result.data.nid
        this.formdata = Object.assign({}, this.formData, {
          name: result.data.name,
          email: result.data.email,
          date_of_birth: result.data.date_of_birth,
          nid: result.data.nid
        })
      } else {
        this.formData.name = ''
        this.formData.email = ''
        this.formData.date_of_birth = ''
        this.formData.nid = ''
        this.$toast.error({
          title: 'Error',
          message: this.$t('elearning_iabm.personalInfoNotFoundMsg'),
          color: '#D6E09B'
        })
      }
    },
    async getCircularPublicationInfo () {
      const result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationCircularPublicationInfo, { circular_memo_no: this.formData.circular_memo_no })
      if (result.success) {
        this.formData.training_type_id = result.data.training_type_id
        this.formData.training_category_id = result.data.training_category_id
        this.formData.training_title_id = result.data.training_title_id
        this.formData.org_id = result.data.org_id
        this.formData.office_type_id = result.data.office_type_id
        this.formData.office_id = result.data.office_id
      } else {
        this.formData.training_type_id = ''
        this.formData.training_category_id = ''
        this.formData.training_title_id = ''
        this.formData.org_id = ''
        this.formData.office_type_id = ''
        this.formData.office_id = ''
        this.$toast.error({
          title: 'Error',
          message: this.$t('elearning_iabm.circularInfoNotFoundMsg'),
          color: '#D6E09B'
        })
      }
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainingApplicationUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingApplicationStore, this.formData)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
