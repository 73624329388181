
<template>
<b-overlay :show="loading">
<b-row>
<b-col>
<list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
    <div class="titleDiv">
      <span class="text-center text-dark reportTitle" style="font-weight:bold;">{{ $t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.details') }}</span><br>
    </div>
</list-report-head>
</b-col>
</b-row>
<b-row>
<b-col lg="12" sm="12">
<b-table-simple bordered>
<tr>
<th style="width: 15%"  class="text-right" >{{ $t('elearning_iabm.accommodation') }}</th>
<th class="text-center" style="width: 5%">:</th>
<td style="width: 30%" class="text-left">{{ $i18n.locale === 'en' ? formData.accommodation_type_name : formData.accommodation_type_name_bn }}</td>
<th style="width: 15%" class="text-right" >{{ $t('elearning_iabm.registration_for') }}</th>
<th class="text-center" style="width: 5%">:</th>
<td style="width: 30%" class="text-left">{{ $i18n.locale === 'en' ? formData.user_type_name : formData.user_type_name_bn }}</td>
</tr>
<tr>
<th style="width: 15%"  class="text-right" >{{ $t('globalTrans.name') }}</th>
<th class="text-center" style="width: 5%">:</th>
<td style="width: 30%" class="text-left">{{ $i18n.locale === 'en' ? formData.name : formData.name_bn }}</td>
<th style="width: 15%" class="text-right" >{{ $t('globalTrans.mobile') }}</th>
<th class="text-center" style="width: 5%">:</th>
<td style="width: 30%" class="text-left">{{
  ($i18n.locale === 'bn' ? '০' : '0') + $n(formData.mobile, { useGrouping: false })
  }}
</td>
</tr>
<tr>
<th style="width: 15%"  class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('elearning_config.organization') }}</th>
<th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</th>
<td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
<th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
<th class="text-center" style="width: 5%">:</th>
<td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
</tr>
<tr>
<th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
<th style="width: 5%" class="text-center">:</th>
<td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
<th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_type') }}</th>
<th style="width: 5%" class="text-center">:</th>
<td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
</tr>
<tr>
<th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_category') }}</th>
<th style="width: 5%" class="text-center">:</th>
<td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
<th style="width: 15%"  class="text-right">{{ $t('elearning_config.training_title') }}</th>
<th style="width: 5%" class="text-center">:</th>
<td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
</tr>
<tr>
<th style="width: 15%"  class="text-right">{{ $t('globalTrans.remarks') }}</th>
<th style="width: 5%" class="text-center">:</th>
<td style="width: 80%" colspan="4">{{ $i18n.locale === 'bn' ? formData.remarks_bn : formData.remarks }}</td>
</tr>
</b-table-simple>
</b-col>
</b-row>
</b-overlay>
</template>
<script>
import ExportPdf from './export_pdf_details_training_application'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    components: {
      ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
      this.formData = this.item
      const accommodationType = this.accommodationTypeList.find(item => item.value === parseInt(this.formData.accommodation_type))
      this.formData.accommodation_type_name = accommodationType?.text_en
      this.formData.accommodation_type_name_bn = accommodationType?.text_bn
    },
    data () {
        return {
            formData: {},
            slOffset: 1,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
      loading () {
        return this.$store.state.commonObj.loading
      },
      accommodationTypeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'With Accommodation' : 'থাকার ব্যবস্থা সহ', text_en: 'With Accommodation', text_bn: 'থাকার ব্যবস্থা সহ', value: 1 },
          { text: this.currentLocale === 'en' ? 'Without Accommodation' : 'থাকার ব্যবস্থা ছাড়া', text_en: 'Without Accommodation', text_bn: 'থাকার ব্যবস্থা ছাড়া', value: 2 }
        ]
      }
    },
    methods: {
        pdfExport () {
            const reportTitle = this.$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.details')
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
        }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .reportTitle {
        font-weight: bold;
        border: 1px solid;
        padding: 8px 15px 8px 15px;
        border-radius: 11px;
  }
  .titleDiv {
    margin-top: 2rem;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
