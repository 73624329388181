<template>
    <div class="inner-section">
         <card>
            <template v-slot:searchHeaderTitle>
                <h4 class="card-title">{{$t('elearning_iabm.training_applications') + ' ' + $t('globalTrans.search')}}</h4>
            </template>
            <template v-slot:searchBody>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="circular_memo_no"
                      >
                      <template v-slot:label>
                        {{$t('elearning_iabm.circular_memo_no')}}
                      </template>
                      <b-form-input
                        id="circular_memo_no"
                        v-model.lazy="search.circular_memo_no"
                        :placeholder="$t('elearning_iabm.circular_memo_no')"
                      ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1"> -->
                    <b-col lg="6" sm="12">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                      >
                      <v-select name="organization"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        :placeholder="$t('globalTrans.select')"
                      />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}}
                      </template>
                      <b-form-select
                        plain
                        v-model="search.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                        <template v-slot:label>
                          {{$t('elearning_config.training_category')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_category_id"
                          :options="trainingCategoryList"
                          id="training_category_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                        <template v-slot:label>
                          {{$t('elearning_config.training_title')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_title_id"
                          :options="trainingTitleList"
                          id="training_title_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('elearning_iabm.registration_for')"
                      label-for="registration_for"
                    >
                      <v-select name="registration_for"
                        v-model="search.registration_for"
                        label="text"
                        :reduce="item => item.value"
                        :options= userTypeList
                        :placeholder="$t('globalTrans.select')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="name"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.name')}}
                      </template>
                      <b-form-input
                          id="name"
                          v-model="search.name"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="mobile"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.mobile')}}
                      </template>
                      <b-form-input
                          type="number"
                          id="mobile"
                          v-model="search.mobile"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-button size="sm" variant="primary" @click="searchData">
                      <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
                    </b-button>
                  </b-col>
                </b-row>
            </template>
        </card>
        <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_iabm.training_applications') + ' ' + $t('globalTrans.list')}}</h4>
            </template>
            <template v-slot:headerAction>
                <b-button class="btn-add" @click="pdfExport">
                  <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                </b-button>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                        <b-col sm="12">
                        <div class="quick-filter-wrapper">
                            <div class="quick-filter-left">
                            <b-dropdown split split-variant="outline-secondary" size="sm" id="dropdown-form" :text="$t('globalTrans.column_visibility')" ref="dropdown">
                                <b-dropdown-form>
                                <div
                                    class="form-group"
                                    v-for="(field, index) in labelData"
                                    :key="index"
                                    >
                                    <b-form-checkbox
                                        :id="'checkbox-' + field.label"
                                        v-model="field.show"
                                        :name="'checkbox-' + field.label"
                                        value=1
                                        unchecked-value=0
                                    >
                                        {{ ($i18n.locale==='bn') ? field.label : field.label }}
                                    </b-form-checkbox>
                                </div>
                                </b-dropdown-form>
                            </b-dropdown>
                            </div>
                            <div class="quick-filter-right">
                            <b-form-group
                                :label="$t('menu.perpage')"
                                label-for="per-page-select"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="search.limit"
                                :options="pageOptions"
                                size="sm"
                                ></b-form-select>
                            </b-form-group>
                            </div>
                        </div>
                        </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" class="table-responsive">
                        <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                          <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                            <template v-slot:cell(serial)="data">
                              {{ $n(data.item.serial + pagination.slOffset) }}
                            </template>
                            <template v-slot:cell(mobile)="data">
                              {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(data.item.mobile, { useGrouping: false }) }}
                            </template>
                            <template v-slot:cell(action)="data">
                              <b-button v-b-modal.modal-5 variant="iq-bg-success mr-1" size="sm" @click="detailData(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                              <b-button v-b-modal.modal-4 variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                            </template>
                          </b-table>
                        </slot>
                        <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                          <b-pagination
                            v-model="pagination.currentPage"
                            :perPage="search.limit"
                            :total-rows="pagination.totalRows"
                            @input="searchData"
                          />
                        </div>
                      </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
      <b-modal id="modal-5" size="lg" :title="$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <template #modal-title class="flex-grow-1">
          {{ $t('elearning_iabm.training_applications') }}
          <b-button variant="success" @click="pdfExportDetails" class="mr-2 float-right">
            <i class="fas fa-print"></i> {{  $t('globalTrans.export_pdf') }}
          </b-button>
        </template>
        <Details :item="item" :key="editItemId" ref="details"/>
      </b-modal>
    </div>
</template>
<script>

import Form from './Form'
import Details from './Details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import {
  trainingApplicationList,
  trainingApplicationApprove,
  trainingApplicationReject
} from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ExportPdf from './export_pdf_details'
import Store from '@/store'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      sortBy: '',
      sortDirection: '',
      sortDesc: '',
      item: [],
      search: {
        registration_for: '',
        name: '',
        circular_memo_no: '',
        org_id: '',
        fiscal_year_id: '',
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        training_status: 1,
        limit: 20,
        mobile: ''
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
        { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '30%' } },
        { labels: 'elearning_iabm.registration_for', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '35%' } },
        { labels: 'globalTrans.name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '35%' } },
        { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '35%' } },
        { labels: 'globalTrans.designation', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '35%' } },
        { labels: 'globalTrans.office', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
        { labels: 'globalTrans.mobile', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } },
        { labels: 'globalTrans.email', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 9, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 10, thStyle: { width: '15%' } }
      ],
      payGradeToList: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      actions: {
        edit: true,
        toogle: true,
        view: true
      },
      editItemId: ''
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    userTypeList () {
      const userList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
      ]
      return userList
    },
    gradeList: function () {
      return this.$store.state.commonObj.gradeList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.training_applications') + ' ' + this.$t('globalTrans.update')
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      /*eslint-disable */
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'user_type_name_bn' },
          { key: 'name_bn' },
          { key: 'org_bn' },
          { key: 'designation_bn' },
          { key: 'office_bn' },
          { key: 'mobile' },
          { key: 'email' },
          // { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'circular_memo_no' },
          { key: 'user_type_name' },
          { key: 'name' },
          { key: 'org' },
          { key: 'designation' },
          { key: 'office' },
          { key: 'mobile' },
          { key: 'email' },
          // { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    }
  },
  async created () {
    /* this.search = Object.assign({}, this.search, {
      org_id: 9
    }) */
    this.loadData()
  },
  watch: {
    'search.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'search.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  methods: {
    dataChange () {
      this.loadData()
    },
    getCategoryList (typeId) {
      const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
      if (typeId) {
        return trainingCategoryList.filter(item => item.training_type_id === typeId)
      }
      return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    detailData (item) {
      this.item = item
    },
    edit (item) {
      this.editItemId = item.id
    },
    getpayGradeTo (gradeId) {
      const grade = this.gradeList
      if (gradeId) {
        return grade.filter(item => item.value > gradeId)
      }
      return grade
    },
    async searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(trainingElearningServiceBaseUrl, payGradeSetupToggleStatus, item)
    },
    officeList (orgId) {
      const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return office.filter(item => item.org_id === orgId)
      }
      return office
    },
    changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
      Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
        if (response.success) {
          /** The following line commented as list is reloaded */
          // Store.dispatch('toggleStatus', item)
          if (destination !== null && dropdownName !== null) {
            Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
          }
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    approve (item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.approveMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatus(trainingElearningServiceBaseUrl, trainingApplicationApprove, item, 'training_e_learning', 'trainerEvaluationList')
        }
      })
    },
    reject (item) {
      window.vm.$swal({
        title: window.vm.$t('globalTrans.rejectMsg'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatus(trainingElearningServiceBaseUrl, trainingApplicationReject, item, 'training_e_learning', 'trainerEvaluationList')
        }
      })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(trainingElearningServiceBaseUrl, trainingApplicationList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
          this.paginationData(response.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getCustomDataList (data) {
      const listData = data.map((item, index) => {
        const customItem = {}
        const userType = this.userTypeList.find(userTypeObj => userTypeObj.value === item.registration_for)
        customItem.user_type_name = userType?.text_en
        customItem.user_type_name_bn = userType?.text_bn
        const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
        if (typeof orgObj !== 'undefined') {
          customItem.org = orgObj.text_en
          customItem.org_bn = orgObj.text_bn
        } else {
          customItem.org = ''
          customItem.org_bn = ''
        }
        const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
        if (typeof OfficeTypeListObj !== 'undefined') {
          customItem.office_type = OfficeTypeListObj.text_en
          customItem.office_type_bn = OfficeTypeListObj.text_bn
        } else {
          customItem.office_type = ''
          customItem.office_type_bn = ''
        }
        const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
        if (typeof officeObj !== 'undefined') {
          customItem.office = officeObj.text_en
          customItem.office_bn = officeObj.text_bn
        } else {
          customItem.office = ''
          customItem.office_bn = ''
        }
        const designationObj = this.$store.state.CommonService.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
        if (typeof designationObj !== 'undefined') {
          customItem.designation = designationObj.text_en
          customItem.designation_bn = designationObj.text_bn
        } else {
          customItem.designation = ''
          customItem.designation_bn = ''
        }
        const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          customItem.fiscal_year = fiscalYearObj.text_en
          customItem.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          customItem.fiscal_year = ''
          customItem.fiscal_year_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
          customItem.training_type = trainingTypeObj.text_en
          customItem.training_type_bn = trainingTypeObj.text_bn
        } else {
          customItem.training_type = ''
          customItem.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
          customItem.training_category = trainingCategoryObj.text_en
          customItem.training_category_bn = trainingCategoryObj.text_bn
        } else {
          customItem.training_category = ''
          customItem.training_category_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
          customItem.training_title = trainingTitleObj.text_en
          customItem.training_title_bn = trainingTitleObj.text_bn
        } else {
          customItem.training_title = ''
          customItem.training_title_bn = ''
        }

        return Object.assign({}, { serial: index }, item, customItem)
      })
      return listData
    },
    pdfExportDetails () {
      this.$refs.details.pdfExport()
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Trainee/Trainer Application' : 'শিক্ষার্থী/প্রশিক্ষক আবেদন'
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this)
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
