import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['15%', '5%', '25%', '15%', '5%', '25%'],
                body: [
                    [
                        { text: vm.$t('elearning_iabm.accommodation'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'en' ? data.accommodation_type_name : data.accommodation_type_name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_iabm.registration_for'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'en' ? data.user_type_name : data.user_type_name_bn, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'en' ? data.name : data.name_bn, style: 'td', alignment: 'left' },
                        { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('externalTraining.email'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'en' ? data.email : data.email, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' },
                        { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' },
                        { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left', colSpan: 4 },
                        {},
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('globalTrans.remarks'), style: 'th', alignment: 'right' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: vm.$i18n.locale === 'bn' ? data.remarks_bn : data.remarks, style: 'td', alignment: 'left', colSpan: 4 },
                        {},
                        {},
                        {}
                    ]
                ]
            }
        })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                th: {
                    fillColor: '',
                    fontSize: 10,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                th1: {
                    fillColor: '',
                    fontSize: 13,
                    bold: true,
                    margin: [3, 3, 3, 3]
                },
                td: {
                    fontSize: 10,
                    margin: [3, 3, 3, 3]
                },
                header: {
                  fontSize: 12,
                  bold: true,
                  alignment: 'center',
                  margin: [0, 0, 0, 0]
                },
                org: {
                    fontSize: 13,
                    bold: true,
                    alignment: 'center',
                    margin: [0, -25, 0, 0]
                },
                address: {
                    fontSize: 11,
                    margin: [0, 0, 0, 5]
                },
                hh: {
                    fontSize: 14,
                    bold: true,
                    margin: [10, 10, 25, 20]
                },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
